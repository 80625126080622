import { LmsProviderType } from './LmsProviderType';
import { AttributeNames } from './Attributes';

export interface UpdateUserObject {
  user?: Partial<User>;
  settings?: Partial<UserSettings>;
  attributes?: UserAttributes;
}

// FIXME: Refactor to support new UserAttributes when possible.
export interface User /* extends IHasAttributes */ {
  xref: string;
  firstName: string;
  lastName: string;
  userName: string;
  displayName: string;
  roles: UserRole[];
  timeZone: string;
  settings?: UserSettings;
  attributes?: UserAttributes;
  createdAt: number;
}

export interface UserSettings {
  [key: string]: boolean | string | number;

  accessToInsightsHub: boolean;
  accessToLDOEReport: boolean;
  alphaTeacherBuilderAccess: boolean;
  anonymizeReportsByDefault: boolean;
  caitEnabled: boolean;
  correctnessFeedback: boolean;
  deliveryOfStudentScore: boolean;
  personalizedTutoringEnabled: boolean;
  quickCommentsTeacher: boolean;
  starredTeacherEnabled: boolean;
  testModeAllowRevisions: boolean;
  testModeCorrectness: boolean;
  testModeStudentReport: boolean;
  useQuickCommentsLive: boolean;
  useRedo: boolean;
  useTeachley: boolean;
  // FIXME: Enable ARRS settings once they are implemented
  // arrsEnabled: boolean;
  // arrsRecommended: boolean;
  // arrsStudiesEnabled: boolean;
  // arrsStrictMode: boolean;
  // arrsDailyLimit: number; // NOTE: range 0-10; 0 signifies 'Unlimited'
  // arrsReleaseDay: ARRSReleaseDay;
  // arrsReleaseTime: string; // NOTE: half hour increments
}

export interface AuthenticatedUser extends User {
  userToken: string;
  userflowSignature: string;
  loginAliasId: string;
  lmsProviderType: LmsProviderType | null;
}

// export type UserAttributes = Pick<
//   AttributesMap,
//   | AttributeNames.NCES_SCHOOL
//   | AttributeNames.CURRICULUM
//   | AttributeNames.GRADE_LEVEL
//   | AttributeNames.QUICK_COMMENTS_TEACHER
// >;

export type UserAttributes = {
  [AttributeNames.NCES_SCHOOL]?: string[];
  [AttributeNames.CURRICULUM]?: string[];
  [AttributeNames.GRADE_LEVEL]?: string[];
  [AttributeNames.QUICK_COMMENTS_TEACHER]?: boolean[];
};

export enum UserRole {
  SERVICE_ADMIN = 'SERVICE_ADMIN',
  CONTENT_ADMIN = 'CONTENT_ADMIN',
  TRUSTED_BUILDER = 'TRUSTED_BUILDER',
  TEACHER = 'TEACHER',
  MENTOR = 'MENTOR',
  STUDENT = 'STUDENT',
}
