var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","data-cy":"problem-set-builder-menu"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{staticClass:"text-body-2"},[(
          _setup.parentProblemSet?.problemSetType ==
          _setup.ProblemSetType.MULTI_PART_PROBLEM_SET
        )?[(_setup.parentProblemSet.xref.startsWith('W'))?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item',_vm._g({staticClass:"disabled-menu-item"},on),[_vm._v(" Copy multi-part Problem ")]),_c('v-list-item',_vm._g({staticClass:"disabled-menu-item"},on),[_vm._v(" Replace multi-part Problem with Copy ")])]}}],null,false,325091069)},[_c('span',[_vm._v("Problem must be published to copy")])]):[(
              _setup.grandparentProblemSet?.permissions.includes(
                _setup.AclPermissionType.UPDATE
              )
            )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _setup.emit('open-copy-pr-dialog', {
                xref: _setup.parent,
                parent: _setup.grandparent,
              })}}},[_vm._v(" Copy multi-part Problem ")]):_vm._e(),(
              _setup.grandparentProblemSet?.permissions.includes(
                _setup.AclPermissionType.UPDATE
              )
            )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _setup.emit('open-copy-pr-dialog', {
                xref: _setup.parent,
                parent: _setup.grandparent,
                replace: true,
              })}}},[_vm._v(" Replace multi-part Problem with Copy ")]):_vm._e()],(
            _setup.grandparentProblemSet?.permissions.includes(
              _setup.AclPermissionType.UPDATE
            )
          )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _setup.openRemoveDialog(_setup.RemoveDialogMode.PARENT)}}},[_vm._v(" Remove multi-part problem ")]):_vm._e(),(
            _setup.parentProblemSet?.permissions.includes(_setup.AclPermissionType.UPDATE)
          )?_c('v-list-item',{attrs:{"link":""},on:{"click":_setup.addNewPart}},[_vm._v(" Add new part ")]):_vm._e()]:_vm._e(),(
          _setup.parentProblemSet?.permissions.includes(_setup.AclPermissionType.UPDATE)
        )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _setup.openRemoveDialog(_setup.RemoveDialogMode.MEMBER)}}},[_vm._v(" Remove "+_vm._s(_setup.memberLabel)+" ")]):_vm._e(),(_setup.member?.xref.startsWith('W'))?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item',_vm._g({staticClass:"disabled-menu-item"},on),[_vm._v(" Copy "+_vm._s(_setup.memberLabel)+" ")]),_c('v-list-item',_vm._g({staticClass:"disabled-menu-item"},on),[_vm._v(" Replace "+_vm._s(_setup.memberLabel)+" with Copy ")])]}}],null,false,1241233277)},[_c('span',[_vm._v(_vm._s(_setup.memberLabel)+" must be published to copy")])]):[(
            _setup.parentProblemSet?.permissions.includes(_setup.AclPermissionType.UPDATE)
          )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _setup.emit('open-copy-pr-dialog', {
              xref: _setup.member?.xref,
              parent: _setup.parent,
            })}}},[_vm._v(" Copy "+_vm._s(_setup.memberLabel)+" ")]):_vm._e(),(
            _setup.parentProblemSet?.permissions.includes(_setup.AclPermissionType.UPDATE)
          )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _setup.emit('open-copy-pr-dialog', {
              xref: _setup.member?.xref,
              parent: _setup.parent,
              replace: true,
            })}}},[_vm._v(" Replace "+_vm._s(_setup.memberLabel)+" with Copy ")]):_vm._e()],(
          _setup.member?.xref.startsWith('W') &&
          _setup.member?.permissions.includes(_setup.AclPermissionType.UPDATE) &&
          _setup.member?.contentType == _setup.ContentType.PROBLEM &&
          _setup.member?.mappedCeri
        )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _setup.emit('open-revert-dialog')}}},[_vm._v(" Revert To Published Version ")]):_vm._e(),(
          _setup.member?.permissions.includes(_setup.AclPermissionType.UPDATE) &&
          _setup.member?.contentType == _setup.ContentType.PROBLEM
        )?_c('v-list-item',{attrs:{"link":""},on:{"click":_setup.editStandards}},[_vm._v(" Edit Standards ")]):_vm._e(),(_setup.member?.contentType == _setup.ContentType.PROBLEM)?_c('v-list-item',{staticClass:"icon-list-item d-flex justify-space-between align-center",attrs:{"link":""},on:{"click":function($event){return _setup.emit('set-test-mode', {
            xref: _setup.member?.xref,
            testMode: !_setup.memberTestMode,
          })}}},[_c('span',[_vm._v(" Test Mode on "+_vm._s(_setup.memberLabel)+" ")]),(_setup.memberTestMode)?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e()],1):_vm._e()],2)],1),(_setup.membership)?_c(_setup.RemoveFromProblemSetDialog,{attrs:{"data":_setup.membership},on:{"removed":function($event){return _setup.navigateToSibling($event)}},model:{value:(_setup.removeDialog),callback:function ($$v) {_setup.removeDialog=$$v},expression:"removeDialog"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }